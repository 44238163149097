.lobbyContainer {
    background-color:#262626;
}
.LobbyCard {
    width:250px;
    margin: 1em;
    border: 1px solid white;
    border-radius: 3px;
}

.LobbyCard .btnRed {
    width:247px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}