#MainContainer {
    background-color: #262626;
    height:100%;
}

.textWhite {
    color:white
}

.textRed {
    color:red;
}

.btnRed {
    background-color: #d4392ea9;
    border: #d4392ea9;
}

.form-group {
    padding-bottom: 1rem;
    margin-bottom:0.5rem;
}
