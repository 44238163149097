.landingBody {
    background-color: #194A68;
    min-height: 100vh;
}

.proxima {
    font-family: proxima-reg;
}

.aptifer {
    font-family: aptifer-reg;
  }

.whiteText {
color:white;
}

.font16 {
font-size: 16px;
}

.font20 {
font-size: 20px;
}
