#contestsContainer {
    background-color: #215975;
}

#Hero {
    background-color: #215975;
}

.bodyRow {
    background-color: #EFEFEF;
}

.bodyCol {
    padding-right: 0px;
    padding-left: 0px;
}

.gamesInProgress {
    padding:0.25em;
    background-color:grey;
    border: 1px solid white;
}

.gamesInProgress.red{
    background-color: #D4382E;
    border: 1px solid white;
   
}

.gamesToday {
    background-color: grey;
    padding:0.25em;
}

.gamesTomorrow {
    background-color: grey;
    padding:0.25em;
}

