.questionRow {
    background-color: #d4d4d4;
    border-radius: 5px;
}

.submitButton {
    background-color: #46AFEF;
    border: 1px solid white;
    color:white;
    padding-left: 2em;
    padding-right: 2em;
    align-items: center;
}



.no-padding {
    padding-left:0px !important;
    padding-right:0px !important;
}

.timer {
    border-radius: 10px;
}

.timerdiv {
    padding-left: 10px;
    
}

.warning {
    background-color: red;
    border-radius: 5px;
    width: 140px;
}

.questionTextDiv {
    height:14vh;
}

.carousel-control-next-icon {
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='grey' width='40' height='40' viewBox='0 0 10 10'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='grey' width='40' height='40' viewBox='0 0 10 10'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon.active{

    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#215975' width='40' height='40' viewBox='0 0 10 10'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon.active {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#215975' width='40' height='40' viewBox='0 0 10 10'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel {
    position:inherit;
}

.carousel-control-next {

    bottom: 110px;
    height: 75px;
    top: 150px;
}

.carousel-control-prev {
    bottom: 110px;
    height: 75px;
    top: 150px;
    left: 20px !important;
}

.carousel-indicators {
    visibility: hidden;
}

.visually-hidden {
    visibility: hidden;
}
.disabledSubmit {
    pointer-events: none;
    background-color: #d4d4d4;
    border: 1px solid black;
    color: black;
}

.greyDiv {
    background-color: #d4d4d4;
    color: black;
}

.hiddenTimer {
    display: none;
}

.modal-header {
    background-color: #fff;
}

.endtextbanner {
    background-color: #215975;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    margin:2px;
    padding: 2px;
}

.gameBanner {
    background-color: white;
    color: Black;
    opacity: 50%;
    border-radius: 20px;
    border: 2px solid black;
    margin: 5px;
    height:2.5rem;
}

.rowHeight {
    height:2.5rem;
}

.liveBtnMiddle {
    margin-top:0.3rem;
}

.liveBtnLeft {
    width: 15px;
    height: 15px;
    background-color: red;
    opacity: 1;
    animation-name: live;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 50%;
    animation-direction: normal;
    margin-top:0.6rem;
 
}

.liveBtnRight {
    width: 15px;
    height: 15px;
    background-color: red;
    opacity: 1;
    animation-name: live;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 50%;
    animation-direction: reverse;
    margin-top:0.6rem;
}

@keyframes live {
  from {opacity:1;}
  to {opacity: 0;}
}

@media only screen and (max-width: 600px) {
    .questionTextDiv {
        height: 15vh;
    }
}