:root {
    --sidebar-width: 350px;
    --toggler-size: 40px;
  }

.TopPanelCont {
    background-color: #181818;
}

.TopPanelCont.profile {
    background-color: #215975;
}

.navbar-toggler {
    border:0px;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(71,173,226, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-light .navbar-nav .nav-link {
    color:#47ADE2;
}

.navbar-light .navbar-nav .nav-link:hover {
    color:white;
}

.sidebar {
  width: var(--sidebar-width);
  height: 80vh;
  transform: translateX(calc(var(--sidebar-width) * -1));
  background: linear-gradient(to bottom right, #47ADE2, White);
  background-size: 400% 400%;
  background-position: 90% 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 998;
  opacity: 0;
  transition: transform .6s, background-position 1s .6s;
}

.input-toggler:checked ~ .sidebar {
  opacity: .9;
  transform: translateX(0);
  background-position: 0 0;
}

.content, .content div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content div[id] {
  display: none;
}

.menu {
  list-style-type: none;
  z-index: 999;
}

.menu__link {
  color: white;
  font-weight: 100;
  text-decoration: none;
  font-size: 3rem;
  line-height: 5rem;
  
}

.menu__link:hover,
.menu__link:focus,
.menu__link:active {
  color: #46AFEF;
}

.menu-toggler {
  display: block;
  width: var(--toggler-size);
  height: var(--toggler-size);
  position: fixed;
  cursor: pointer;
  border-radius: 4px;
  z-index: 999;
  position: absolute;

}

.white {
  background: white;
}



.menu-toggler__line {
  height: calc(var(--toggler-size) / 5);
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  transition-property: transform, opacity;
  transition-duration: .5s, .25s;
  transition-delay: 0s, .5s;
  animation-name: slidein;
  animation-duration: .5s;
  animation-iteration-count:1;
  animation-timing-function: ease-out;
  border-radius: 4px;
}

.input-toggler:checked ~ .menu-toggler .menu-toggler__line {
  transition-delay: .25s, 0s;
}


.menu-toggler__line:nth-child(2) {
  top: calc(var(--toggler-size) / 5 * 2);
animation-duration: .6s;
}

.menu-toggler__line:nth-child(3) {
  top: calc(var(--toggler-size) / 5 * 4);
    animation-duration:.7s;
}

.input-toggler {
  display: none;

}

.input-toggler:checked ~ .menu-toggler .menu-toggler__line {
  background: White;
}

.input-toggler:checked ~ .menu-toggler .menu-toggler__line:nth-child(1) {
  transform: translateY(calc(var(--toggler-size) / 5 * 2)) rotate(45deg);
}

.input-toggler:checked ~ .menu-toggler .menu-toggler__line:nth-child(2) {
  opacity: 0;
}

.input-toggler:checked ~ .menu-toggler .menu-toggler__line:nth-child(3) {
  transform: translateY(calc(var(--toggler-size) / 5 * -2)) rotate(-45deg);
}

@keyframes slidein {
  from {left: -200%; right:200%;}
  to {left: 0; right: 0;}
}